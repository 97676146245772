var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prizeOrder" },
    [
      _c("h3", [_vm._v("奖品订单")]),
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "el-input",
            {
              attrs: {
                maxlength: "50",
                placeholder: "请输入订单号",
                oninput: "value = value.replace(/[^0-9]/g,'')"
              },
              model: {
                value: _vm.searchContent,
                callback: function($$v) {
                  _vm.searchContent = $$v
                },
                expression: "searchContent"
              }
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: { click: _vm.search },
                slot: "append"
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: {
              prop: "orderNo",
              "header-align": "center",
              align: "center",
              label: "订单号"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              label: "产品",
              width: "280"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "product-name" },
                      _vm._l(scope.row.itemList, function(item, index) {
                        return _c("span", { key: index }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.productName +
                                  (index == scope.row.itemList.length - 1
                                    ? ""
                                    : "+")
                              ) +
                              " "
                          )
                        ])
                      }),
                      0
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              label: "订单时间"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("filterTime")(scope.row.createTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderAmount",
              "header-align": "center",
              align: "center",
              label: "订单金额"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(scope.row.orderAmount.toFixed(2)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              label: "订单状态"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.orderStatusList[scope.row.orderStatus]))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { "header-align": "center", align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderStatus == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "danger", round: "" },
                            on: {
                              click: function($event) {
                                return _vm.toPay(scope.row.orderNo)
                              }
                            }
                          },
                          [_vm._v("去支付")]
                        )
                      : (scope.row.orderStatus == 1 ||
                          scope.row.orderStatus == 4) &&
                        scope.row.invoiceStatus == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.getInvoiceList(scope.row.orderNo)
                              }
                            }
                          },
                          [_vm._v("申请开票")]
                        )
                      : (scope.row.orderStatus == 1 ||
                          scope.row.orderStatus == 4) &&
                        scope.row.invoiceStatus == 1
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#606266" },
                            attrs: { type: "text", size: "small" }
                          },
                          [_vm._v("待开票")]
                        )
                      : (scope.row.orderStatus == 1 ||
                          scope.row.orderStatus == 4) &&
                        scope.row.invoiceStatus == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.downloadInvoice(scope.row.invoiceUrl)
                              }
                            }
                          },
                          [_vm._v("下载发票")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.getOrderDetail(scope.row.orderNo)
                          }
                        }
                      },
                      [_vm._v("订单明细")]
                    ),
                    scope.row.orderStatus == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.cancel(scope.row.orderNo)
                              }
                            }
                          },
                          [_vm._v("取消")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticClass: "page",
        attrs: {
          "current-page": _vm.page,
          "page-sizes": [10, 20, 50, 100],
          "page-size": _vm.limit,
          total: _vm.totalPage,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "size-change": _vm.sizeChangeHandle,
          "current-change": _vm.currentChangeHandle
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "支付订单",
            visible: _vm.payVisible,
            "append-to-body": "",
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.payVisible = $event
            }
          }
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.payType,
                callback: function($$v) {
                  _vm.payType = $$v
                },
                expression: "payType"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "微信支付", name: "first" } },
                [
                  _c(
                    "div",
                    { staticClass: "alipay" },
                    [
                      _c("p", [
                        _vm._v("应付金额："),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s("￥" + _vm.money + "元"))
                        ])
                      ]),
                      _c("active-qrcode", {
                        attrs: { url: _vm.payCode, codeSize: "180" }
                      }),
                      _c("p", [
                        _c("img", {
                          staticStyle: { "margin-right": "5px" },
                          attrs: {
                            src: require("../../../assets/img/marketing/wx.png"),
                            alt: "",
                            width: "25px"
                          }
                        }),
                        _vm._v(" 微信扫码付款 ")
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单明细",
            visible: _vm.orderVisible,
            "append-to-body": "",
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.orderVisible = $event
            }
          }
        },
        [
          _c("h4", { staticClass: "title-name" }, [_vm._v("基本信息")]),
          _c("div", { staticClass: "detail-info" }, [
            _c("div", { staticClass: "detail-item" }, [
              _vm._v("订单号：" + _vm._s(_vm.detail.orderNo))
            ]),
            _c("div", { staticClass: "detail-item" }, [
              _vm._v(
                "订单状态：" +
                  _vm._s(_vm.orderStatusList[_vm.detail.orderStatus])
              )
            ]),
            _c("div", { staticClass: "detail-item" }, [
              _vm._v(
                "支付方式：" +
                  _vm._s(
                    _vm.detail.payType
                      ? _vm.payTypeList[_vm.detail.payType - 1]
                      : "未支付"
                  )
              )
            ]),
            _c("div", { staticClass: "detail-item" }, [
              _vm._v(
                "下单时间：" +
                  _vm._s(_vm._f("filterTime")(_vm.detail.createTime))
              )
            ]),
            _vm.detail.payTime
              ? _c("div", { staticClass: "detail-item" }, [
                  _vm._v(
                    "支付时间：" +
                      _vm._s(_vm._f("filterTime")(_vm.detail.payTime))
                  )
                ])
              : _c("div", { staticClass: "detail-item" }, [
                  _vm._v("支付时间：未支付")
                ])
          ]),
          _c("h4", { staticClass: "title-name" }, [_vm._v("发票信息")]),
          _c("div", { staticClass: "detail-info" }, [
            _vm.detail.invoiceStatus == 0
              ? _c("div", { staticClass: "detail-item" }, [_vm._v("暂不开票")])
              : _vm.detail.invoiceStatus != 0 && _vm.detail.invoiceTitle
              ? _c("div", [
                  JSON.parse(_vm.detail.invoiceTitle).ifVat == 1
                    ? _c("div", [
                        _c("div", { staticClass: "detail-item" }, [
                          _vm._v("发票类型：增值普通发票")
                        ]),
                        _c("div", { staticClass: "detail-item" }, [
                          _vm._v(
                            "公司名称：" +
                              _vm._s(
                                JSON.parse(_vm.detail.invoiceTitle)
                                  .vatInvoiceTitle
                              )
                          )
                        ]),
                        _c("div", { staticClass: "detail-item" }, [
                          _vm._v(
                            "纳税人识别号：" +
                              _vm._s(
                                JSON.parse(_vm.detail.invoiceTitle)
                                  .vatTaxpayerNum
                              )
                          )
                        ])
                      ])
                    : JSON.parse(_vm.detail.invoiceTitle).ifVat == 2
                    ? _c("div", [
                        _c("div", { staticClass: "detail-item" }, [
                          _vm._v("发票类型：增值专用发票")
                        ]),
                        _c("div", { staticClass: "detail-item" }, [
                          _vm._v(
                            "单位名称：" +
                              _vm._s(
                                JSON.parse(_vm.detail.invoiceTitle)
                                  .vatInvoiceTitle
                              )
                          )
                        ]),
                        _c("div", { staticClass: "detail-item" }, [
                          _vm._v(
                            "注册地址：" +
                              _vm._s(
                                JSON.parse(_vm.detail.invoiceTitle)
                                  .vatCompanyAddress
                              )
                          )
                        ]),
                        _c("div", { staticClass: "detail-item" }, [
                          _vm._v(
                            "注册电话：" +
                              _vm._s(
                                JSON.parse(_vm.detail.invoiceTitle).vatTelphone
                              )
                          )
                        ]),
                        _c("div", { staticClass: "detail-item" }, [
                          _vm._v(
                            "开户银行：" +
                              _vm._s(
                                JSON.parse(_vm.detail.invoiceTitle).vatBankName
                              )
                          )
                        ]),
                        _c("div", { staticClass: "detail-item" }, [
                          _vm._v(
                            "银行账户：" +
                              _vm._s(
                                JSON.parse(_vm.detail.invoiceTitle)
                                  .vatBankAccount
                              )
                          )
                        ])
                      ])
                    : JSON.parse(_vm.detail.invoiceTitle).ifVat == 3
                    ? _c("div", [
                        _c("div", { staticClass: "detail-item" }, [
                          _vm._v("发票类型：个人")
                        ]),
                        _c("div", { staticClass: "detail-item" }, [
                          _vm._v(
                            "抬头名称：" +
                              _vm._s(
                                JSON.parse(_vm.detail.invoiceTitle)
                                  .vatInvoiceTitle
                              )
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ]),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "50px" },
              attrs: { data: _vm.detail.itemList }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "productName",
                  "header-align": "center",
                  align: "center",
                  label: "商品名称"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "price",
                  "header-align": "center",
                  align: "center",
                  label: "价格",
                  width: "280"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "quantity",
                  "header-align": "center",
                  align: "center",
                  label: "数量"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  align: "center",
                  label: "小计"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (scope.row.quantity * scope.row.price).toFixed(2)
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right", margin: "20px 120px 0 0" }
            },
            [
              _vm._v("合计："),
              _c("span", { staticStyle: { color: "red" } }, [
                _vm._v(_vm._s(parseFloat(_vm.totalPrice).toFixed(2)) + "元")
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发票信息",
            visible: _vm.invoiceVisible,
            "append-to-body": "",
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.invoiceVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "right", "margin-bottom": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/PersonalCenter/InvioceInfo")
                    }
                  }
                },
                [_vm._v("管理发票")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.invoiceData, border: "", "show-header": false }
            },
            [
              _c("el-table-column", {
                attrs: { width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.$index },
                            nativeOn: {
                              change: function($event) {
                                return _vm.getCurrentRow(scope.row)
                              }
                            },
                            model: {
                              value: _vm.currentRow,
                              callback: function($$v) {
                                _vm.currentRow = $$v
                              },
                              expression: "currentRow"
                            }
                          },
                          [_vm._v(_vm._s())]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.invoiceTypeList[scope.row.ifVat - 1])
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", { attrs: { prop: "vatInvoiceTitle" } })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sub } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }