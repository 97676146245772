<template>
  <div class="prizeOrder">
    <h3>奖品订单</h3>
    <div class="search"> 
        <el-input maxlength="50" placeholder="请输入订单号" v-model="searchContent" oninput = "value = value.replace(/[^0-9]/g,'')">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="orderNo"
        header-align="center"
        align="center"
        label="订单号">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="产品"
        width="280">
        <template slot-scope="scope">
          <div class="product-name">
            <span v-for="(item, index) in scope.row.itemList" :key="index">
              {{ item.productName + (index == scope.row.itemList.length - 1 ? '' : '+')}}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="订单时间">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | filterTime }}</span>
        </template>
      </el-table-column>
        <el-table-column
        prop="orderAmount"
        header-align="center"
        align="center"
        label="订单金额">
        <template slot-scope="scope">
          <span style="color: red">{{ scope.row.orderAmount.toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="订单状态">
        <template slot-scope="scope">
          <span>{{ orderStatusList[scope.row.orderStatus] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="操作">
        <template slot-scope="scope">
          <el-button size="small" type="danger" round @click="toPay(scope.row.orderNo)" v-if="scope.row.orderStatus == 0">去支付</el-button>
          <el-button type="text" size="small" v-else-if="(scope.row.orderStatus == 1 || scope.row.orderStatus == 4) && scope.row.invoiceStatus == 0" @click="getInvoiceList(scope.row.orderNo)">申请开票</el-button>
          <el-button type="text" size="small" style="color: #606266" v-else-if="(scope.row.orderStatus == 1  || scope.row.orderStatus == 4) && scope.row.invoiceStatus == 1">待开票</el-button>
          <el-button type="text" size="small" v-else-if="(scope.row.orderStatus == 1 || scope.row.orderStatus == 4) && scope.row.invoiceStatus == 2" @click="downloadInvoice(scope.row.invoiceUrl)">下载发票</el-button>
          <el-button type="text" size="small" @click="getOrderDetail(scope.row.orderNo)">订单明细</el-button>
          <el-button type="text" size="small" v-if="scope.row.orderStatus == 0" @click="cancel(scope.row.orderNo)">取消</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="page"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="limit"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>

    <!-- 支付 -->
    <el-dialog
      title="支付订单"
      :visible.sync="payVisible"
      append-to-body
      width="60%">
      <el-tabs v-model="payType" @tab-click="handleClick">
        <el-tab-pane label="微信支付" name="first">
          <div class="alipay">
            <p>应付金额：<span style="color: red">{{ '￥' + money + '元'}}</span></p>
            <active-qrcode :url="payCode" codeSize="180"></active-qrcode>
            <p>
              <img src="../../../assets/img/marketing/wx.png" alt="" width="25px" style="margin-right: 5px">
              微信扫码付款
            </p>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="支付宝支付" name="second">
          <div class="alipay"> -->
            <!-- <p>应付金额：<span style="color: red">{{ '￥' + money + '元'}}</span></p>
            <active-qrcode :url="payCode" codeSize="180"></active-qrcode>
            <p>
              <img src="../../../assets/img/marketing/alipay.png" alt="" width="50px">
              支付宝扫码付款
            </p> -->
            <!-- 暂不支持此方式付款！
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </el-dialog>

    <!-- 明细 -->
    <el-dialog
      title="订单明细"
      :visible.sync="orderVisible"
      append-to-body
      width="60%">
      <h4 class="title-name">基本信息</h4>
      <div class="detail-info">
        <div class="detail-item">订单号：{{ detail.orderNo }}</div>
        <div class="detail-item">订单状态：{{ orderStatusList[detail.orderStatus]  }}</div>
        <div class="detail-item">支付方式：{{ detail.payType ? payTypeList[detail.payType - 1] : '未支付' }}</div>
        <div class="detail-item">下单时间：{{ detail.createTime | filterTime }}</div>
        <div class="detail-item" v-if="detail.payTime">支付时间：{{ detail.payTime | filterTime }}</div>
        <div class="detail-item" v-else>支付时间：未支付</div>
      </div>
      <h4 class="title-name">发票信息</h4>
      <div class="detail-info">
        <div class="detail-item" v-if="detail.invoiceStatus == 0">暂不开票</div>
        <div v-else-if="detail.invoiceStatus != 0 && detail.invoiceTitle">
          <div v-if="JSON.parse(detail.invoiceTitle).ifVat == 1">
            <div class="detail-item">发票类型：增值普通发票</div>
            <div class="detail-item">公司名称：{{ JSON.parse(detail.invoiceTitle).vatInvoiceTitle }}</div>
            <div class="detail-item">纳税人识别号：{{ JSON.parse(detail.invoiceTitle).vatTaxpayerNum }}</div>
          </div>
          <div v-else-if="JSON.parse(detail.invoiceTitle).ifVat == 2">
            <div class="detail-item">发票类型：增值专用发票</div>
            <div class="detail-item">单位名称：{{ JSON.parse(detail.invoiceTitle).vatInvoiceTitle }}</div>
            <div class="detail-item">注册地址：{{ JSON.parse(detail.invoiceTitle).vatCompanyAddress }}</div>
            <div class="detail-item">注册电话：{{ JSON.parse(detail.invoiceTitle).vatTelphone }}</div>
            <div class="detail-item">开户银行：{{ JSON.parse(detail.invoiceTitle).vatBankName }}</div>
            <div class="detail-item">银行账户：{{ JSON.parse(detail.invoiceTitle).vatBankAccount }}</div>
          </div>
          <div v-else-if="JSON.parse(detail.invoiceTitle).ifVat == 3">
            <div class="detail-item">发票类型：个人</div>
            <div class="detail-item">抬头名称：{{ JSON.parse(detail.invoiceTitle).vatInvoiceTitle }}</div>
          </div>
        </div>
      </div>

      <el-table
      :data="detail.itemList"
      style="width: 100%; margin-top: 50px">
      <el-table-column
        prop="productName"
        header-align="center"
        align="center"
        label="商品名称">
      </el-table-column>
      <el-table-column
        prop="price"
        header-align="center"
        align="center"
        label="价格"
        width="280">
      </el-table-column>
      <el-table-column
        prop="quantity"
        header-align="center"
        align="center"
        label="数量">
      </el-table-column>
        <el-table-column
        header-align="center"
        align="center"
        label="小计">
        <template slot-scope="scope">
          <span>{{ (scope.row.quantity * scope.row.price).toFixed(2) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right; margin: 20px 120px 0 0">合计：<span style="color: red">{{ parseFloat(totalPrice).toFixed(2) }}元</span></div>
    </el-dialog>

    <!-- 申请开票 -->
    <el-dialog
      title="发票信息"
      :visible.sync="invoiceVisible"
      append-to-body
      width="30%">
      <div style="text-align: right; margin-bottom: 20px">
        <el-button size="small" @click="$router.push('/PersonalCenter/InvioceInfo')">管理发票</el-button>
      </div>
      <el-table
          :data="invoiceData"
          border
          :show-header="false"
          style="width: 100%">
          <el-table-column width="80">
            <template slot-scope="scope">
              <el-radio
                :label="scope.$index"
                v-model="currentRow"
                @change.native="getCurrentRow(scope.row)"
              >{{ }}</el-radio>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <span>{{ invoiceTypeList[scope.row.ifVat - 1] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="vatInvoiceTitle">
          </el-table-column>
        </el-table>
        <div style="text-align: right; margin-top: 20px">
          <el-button type="primary" @click="sub">确认</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderList } from '@/api/buyCar'
import { getPayInfo, getOrderStatus, cancelOrder, updateOrder } from '@/api/buyCar'
import ActiveQrcode from '@/components/ActiveQrcode.vue'
import { getInvoiceInfo } from '@/api/userInfo'
import { getLocal } from '@/lib/local'

export default {
  data () {
    return {
      tableData: [],
      page: 1,
      limit: 10,
      totalPage: 0,
      orderStatusList: ['待付款', '已支付', '已取消', '已失效', '已完成'],
      searchContent: '',
      payCode: '',
      money: '',
      payVisible: false,
      payType: 'first',
      timeID: null,
      orderVisible: false,
      detail: '',
      payTypeList: ['微信支付', '支付宝支付'],
      totalPrice: 0,
      invoiceVisible: false,
      invoiceData: [],
      invoiceTypeList: ['增值普通发票', '增值专用发票', '个人发票'],
      currentRow: 0,
      orderNo: '',
      row: ''
    }
  },
  components: {
    ActiveQrcode
  },
  mounted () {
    this.getList()
  },
  watch: {
    payVisible(val) {
      if(!val) {
        clearInterval(this.timeID)
        this.timeID = null
      }
    },
  },
  methods: {
    // 查询订单
    async getList (orderNo) {
      const res = await getOrderList({pageSize: this.limit, pageNo: this.page, orderNo})
      if(res.code == 200) {
        this.tableData = res.data.list
        this.totalPage = res.data.total
      }
    },
    // 每页数
    sizeChangeHandle (val) {
      this.limit = val
      this.page = 1
      this.getList(this.searchContent)
    },
    // 当前页
    currentChangeHandle (val) {
      this.page = val
      this.getList(this.searchContent)
    },
    handleClick(tab, event) {
      // console.log(tab)
    },
    search () {
      this.page = 1
      this.getList(this.searchContent)
    },
    // 去支付
    async toPay (orderNo) {
      const res = await getPayInfo({orderNo, payType: 1})
      if(res.code == 200) {
        this.payCode = res.data.codeUrl
        this.money = res.data.payAmount
        this.payType = 'first'
        this.payVisible = true
        this.getPayStatus(orderNo)
      }
    },
    // 查看订单支付状态
    getPayStatus (orderNo) {
      this.timeID = setInterval(async () => {
        const res = await getOrderStatus({orderNo})
        if(res.code == 200 && res.data == 1) {
          this.$message.success('支付成功')
          this.payVisible = false
          this.getList()
        }else if(res.code == 200 && res.data == 2) {
          this.$message('订单已取消')
          this.payVisible = false
          this.getList()
        }else if(res.code == 200 && res.data == 3) {
          this.$message('订单已失效')
          this.payVisible = false
          this.getList()
        }
      }, 3000)
    },
    // 明细
    async getOrderDetail (orderNo) {
      const res = await getOrderList({pageSize: 10, pageNo: 1, orderNo})
      if(res.code == 200) {
        this.detail = res.data.list[0]
        this.totalPrice = 0
        this.detail.itemList.forEach((item) => {
          this.totalPrice += item.quantity * item.price
        })
      }
      this.orderVisible = true
    },
    // 取消
    async cancel (orderNo) {
      const res = await cancelOrder({orderNo})
      if(res.code == 200) {
        this.$message.success('取消订单成功')
        this.getList()
      }
    },
    // 申请开票
    async getInvoiceList (orderNo) {
      this.orderNo = orderNo
      const res = await getInvoiceInfo({limit: 20, page: 1})
      if(res.code == 0) {
        this.invoiceData = res.data.list
        this.currentRow = 0
        this.invoiceVisible = true
      }
    },
    // 选择发票
    getCurrentRow (row) {
      this.row = row
    },
    // 开票
    async sub () {
      const res = await updateOrder({orderNo: this.orderNo, invoiceTitle: JSON.stringify(this.row ? this.row : this.invoiceData[0])})
      if(res.code == 200) {
        this.$message.success('申请开票成功')
        this.invoiceVisible = false
        this.getList()
      }
    },
    // 下载
    async downloadInvoice (invoiceUrl) {
      // window.location.href = `${this.$url}/cube_web_api/shopping/order/download?orderNo=${orderNo}&token=${getLocal()}`
      if(invoiceUrl) {
        window.open(invoiceUrl, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.prizeOrder{
  padding: 30px;
  background-color: white;
  .page {
    margin-top: 50px;
    text-align: right;
  }
  .search {
    margin: 20px 0;
    /deep/ .el-input__inner {
      width: 300px;
    }
    /deep/ .el-input {
      width: 300px;
    }
  }
  /deep/ .el-input__inner {
    width: 100%;
  }
  .product-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
.alipay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 340px;
  p {
    display: flex;
    align-items: center;
    margin: 30px 0;
  }
}
.title-name {
  margin: 20px 0;
}
.detail-info {
  padding-left: 20px;
}
.detail-item {
  margin: 5px 0;
}
</style>